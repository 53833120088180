import { fetchGet } from '../../router/https'
//订单详情
export function getOrderDetail(params) {
    return fetchGet('/mallapp/goodAuction/orderAuction', params);
}
//选择收货地址
export function getAddress(params) {
    return fetchGet('/mallapp/goodAuction/setLogistics', params);
}
//选择收货地址
export function delSouthOrder(params) {
    return fetchGet('/mallapp/goodAuction/deleteOrderAuction', params);
}
//申请退款
export function getReturnMoney(params) {
    return fetchGet('/mallapp/orderinfo/auctionRefunds', params);
}
//确认收货
export function getGoodsConfirm(params) {
    return fetchGet('/mallapp/goodAuction/receipt', params);
}
//获取物流信息
export const getOrderLogistics = (params) => {
    return fetchGet('/mallapp/orderrefunds/dict?type=LOGISTICS', params);
}
//立即付款
export const getMoneyPay = (params) => {
    return fetchGet('/mallapp/orderinfo/auctionPay', params);
}