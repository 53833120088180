<template>
    <div class="main">
        <!-- 物流 -->
        <van-action-sheet v-model="show" title="物流详情" safe-area-inset-bottom :closeable="false" @close="closeLogisticsWin">
            <div class="logisticsTop">
                <div class="left">
                    <img src="@/assets/img/logistics02.png" alt="图片加载失败" />
                    <span>{{ logisticsName }}: {{ logisticsData.nu }}</span>
                </div>
                <div class="right">
                    <span class="leftSpan copy" :dataClipboard="logisticsData.nu"
                        @click="copyOrderId('.leftSpan')">复制</span>
                    <!-- <span>官方电话</span> -->
                </div>
            </div>
            <van-steps direction="vertical" :active="0" active-color="#E54320">
                <van-step class="logisticsText" v-for="(v, i) in logisticsData.data" :key="i">
                    <h5>{{ v.context }}</h5>
                    <span>{{ v.ftime }}</span>
                </van-step>
            </van-steps>
        </van-action-sheet>
    </div>
</template>
  
<script>
import { Toast } from "vant";
import ClipboardJS from "clipboard";
import { getOrderLogistics } from '@/api/southMarketOrder/index.js'
export default {
    data() {
        return {
            show: true,
            logisticsName: ''
        };
    },
    props: {
        logisticsData: {
            type: Object,
            required: true
        }
    },
    beforeMount() {

    },
    activated() {

    },
    mounted() {
        let screenWidth = document.documentElement.getBoundingClientRect().width;
        document.documentElement.style.fontSize = screenWidth / 10 + "px";
        // this.getPhone()
        this.getPageInfo()
    },

    methods: {
        //关闭页面
        closeLogisticsWin() {
            this.$emit('close', false)
        },
        //页面物流数据
        getPageInfo() {
            getOrderLogistics()
                .then((res1) => {
                    this.logisticsName = res1.data.data.filter(
                        (v) => v.value === this.logisticsData.com
                    )[0].label;
                })
                .catch(() => {
                    // Toast.fail("物流查询失败", err);
                });
        },
        //复制方法
        copyOrderId(dom) {
            // console.log(dom, '???');
            // 1. 绑定剪贴板操作方法(订单号)
            let clipboard = new ClipboardJS(dom, {
                text: function (trigger) {
                    // 2. trigger 就是点击的 dom 元素，所以就可以通过它来获取到它的属性 'dataClipboard' 的值了
                    // 3. 从这个方法返回的 string 就是会复制到剪贴板中的内容，所以可以复制任何内容了，也可以在这里处理一些逻辑
                    // 4. 我在这里就只是单纯的输出了事先绑定好的值
                    // console.log(trigger.getAttribute('dataClipboard'));
                    return trigger.getAttribute("dataClipboard");
                },
            });
            // 5. 当复制成功的时候提示复制成功
            clipboard.on("success", () => {
                // 还可以添加监听事件，如：复制成功后提示
                Toast.success("复制成功");
            });
        },

    }
};
</script>
  
<style lang="less" scoped>
.main {
    box-sizing: border-box;
    width: 100%;

    // height: 100%;
    .copy {
        color: #E54320;
        margin-right: 0.2595rem;
        text-decoration: underline;
    }

    .logisticsTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px;

        .left {
            display: flex;
            align-items: center;
            // justify-content: space-between;
        }
    }
}
</style>
  