<template>
    <div class="main">
        <!-- 立即付款 -->
        <van-popup class="popup" position="bottom" round :style="{ height: '36%' }" v-model="show" @close="closePayWin">
            <div class="popup-main">
                <div class="divOne">
                    <p>{{ payInfo.payText }}支付</p>
                </div>
                <div class="divTwo">
                    <div class="text">
                        <p class="p1">中标金额({{ payInfo.payText }})</p>
                        <p><span style="color: #E54320;font-size: 18px;">{{ payInfo.bidding }}</span>&ensp;{{
                            payInfo.payText }}</p>
                    </div>
                    <!-- <div class="text">
                        <p class="p1">保证金抵扣({{ payInfo.payText }})</p>
                        <p><span style="color: #E54320;font-size: 18px;">{{ payInfo.margin }}</span>&ensp;{{ payInfo.payText
                        }}</p>
                    </div>
                    <div class="text fontColor">
                        <p class="p2">还需支付({{ payInfo.payText }})</p>
                        <p><span style="color: #E54320;font-size: 18px;">{{ payInfo.bidding - payInfo.margin
                        }}</span>&ensp;{{ payInfo.payText }}</p>
                    </div> -->
                </div>
                <div class="divThree">
                    <van-button @click="payMoney" round size="small" color="#E54320" hairline style="width:100%;"><span
                            style="color: #fffff;font-size:18px;">立&ensp;即&ensp;支&ensp;付</span></van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>
  
<script>
import { Toast } from "vant";
import {
    getPayStatus
} from "@/api/yundian/orderIdDetail/index.js";
import { getMoneyPay } from '@/api/southMarketOrder/index.js'
export default {
    data() {
        return {
            show: true,
            redRiceInfo: [],
        };
    },
    props: {
        payInfo: {
            type: Object,
            required: true
        },
    },
    beforeMount() {
        getPayStatus()
            .then((res) => {
                this.redRiceInfo = res.data.data.shopJoinUser;
                console.log(res.data.data.shopJoinUser, '???');
            })
            .catch(() => {
            });
    },
    activated() {

    },
    mounted() {
        let screenWidth = document.documentElement.getBoundingClientRect().width;
        document.documentElement.style.fontSize = screenWidth / 10 + "px";
        // this.getPhone()
        // this.getPageInfo()
    },

    methods: {
        //关闭页面
        closePayWin() {
            this.$emit('close', false)
        },
        //立即付款
        payMoney() {
            // console.log(this.payInfo.id, '78');
            getMoneyPay({ id: this.payInfo.id }).then(res => {
                if (res.data.code == 0) {
                    Toast.success('支付成功')
                    this.closePayWin()
                    this.$parent.sendPhone('1')
                } else {
                    Toast.fail(res.data.message)
                }
            }).catch(() => {
                Toast.fail('支付失败，请联系管理员')
            })
        }

    }
};
</script>
  
<style lang="less" scoped>
.main {
    box-sizing: border-box;



    .popup {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 10px;

        .popup-main {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .divOne {
                margin: 10px 0;
                font-size: 19px;
                color: #333333;
            }

            .divTwo {
                width: 100%;
                font-size: 15px;

                .text {
                    margin: 20px 0;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .p1 {
                        color: #878787;
                    }

                    .p2 {
                        color: #333333;
                    }
                }

            }

            .divThree {
                width: 100%;
                height: 1rem;

                .van-button {
                    height: 1.1rem;
                }
            }
        }
    }
}
</style>
  