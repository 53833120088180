<template>
    <div class="main">
        <!--南市--订单详情导航栏--->
        <section class="top">
            <van-nav-bar title="订单详情" safe-area-inset-top z-index="1000" left-arrow :fixed="isTop"
                @click-left="onClickLeft" />
        </section>
        <!-- 订单状态 -->
        <section class="willPay">
            <div class="willPayD1">
                <img src="@/assets/img/willPay04.png" alt="" />
            </div>
            <div>
                <!-- 订单状态0：竞拍中 1：待付款 2：待使用/待发货 3：已发货 4：已完成 5:已取消 6：竞拍失败 7超时未付款-->
                <p>{{ goodsDetail.status == '0' ? '竞拍中' : goodsDetail.status == '1' ? '待付款' : goodsDetail.status == '2' ?
                    '待发货' : goodsDetail.status == '3' ? '已发货' : goodsDetail.status == '4' ? '已完成' : goodsDetail.status ==
                        '5' ? '交易失败' : goodsDetail.status == '6' ? '交易失败' : goodsDetail.status == '7' ? '交易违约' : '暂无状态' }}</p>
                <div v-show="goodsDetail.status == '6'">
                    <p>失败原因：未中拍，保证金已原路退回。</p>
                    <p>结束时间：{{ goodsDetail.updateTime }}</p>
                </div>
                <div v-show="goodsDetail.status == '5'">
                    <p>失败原因：申请退款，交易违约扣除保证金</p>
                    <p>结束时间：{{ goodsDetail.updateTime }}</p>
                </div>
                <div v-show="goodsDetail.status == '7'">
                    <p>失败原因：超时未付款，交易违约扣除保证金</p>
                    <p>结束时间：{{ goodsDetail.updateTime }}</p>
                </div>
                <div v-show="goodsDetail.status == '1'">
                    <p>剩余时间：{{ closeTime.hours }}时{{ closeTime.minutes }}分{{
                        closeTime.second
                    }}秒</p>
                    <p style="color: #E54320;">若中拍后逾期未支付货款，将扣除保证金。</p>
                </div>
            </div>
        </section>
        <!-- 收件人信息 -->
        <section>
            <div class="willPay" v-show="userAddress.userName">
                <div class="willPayD1">
                    <img src="@/assets/img/willPay03.png" alt="" />
                </div>
                <div>
                    <p><span>{{ userAddress.userName || '' }}</span>&ensp;<span>{{
                        userAddress.telNum || ''
                    }}</span>
                    </p>
                    <p>{{
                        userAddress.provinceName + userAddress.cityName +
                        userAddress.countyName + userAddress.detailInfo
                        || '' }}</p>
                </div>

            </div>
            <div class="willPayLog" v-show="!userAddress.userName && goodsDetail.status != '6' && goodsDetail.status != '7'"
                @click="$router.push({ path: '/shippingAddress', query: { first: 0 } })">
                <div>
                    <van-icon size="0.5405rem" name="add-o" />
                </div>
                <div>点击添加收货地址</div>
            </div>
        </section>
        <!-- 商品信息 -->
        <section class="shopInfo">
            <div class="goodsInfo">
                <div class="goodsList">
                    <img class="goodsImg" :src="picUrl" alt="暂无图片，请退出重试" />
                    <div class="goodsText">
                        <div>
                            <p>{{ goodsDetail.goodsAuction.goodsName || '--' }}</p>
                            <!-- <div class="size fontColor">
                                <span>规格：</span>
                                <span>x</span>
                            </div> -->
                        </div>
                        <div class="price">
                            <p>{{ goodsDetail.bidding || '--' }}&nbsp;{{ goodsDetail.payText }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 订单编号等信息 -->
        <section class="orderInfo">
            <div class="orderNum">
                <span class="orderNum1">订单编号</span>
                <p class="orderNumP">
                    <span class="orderNumPS">{{ goodsDetail.id || '--' }}</span>
                    <span class="copy clipboard" @click="copyOrderId('.clipboard')"
                        :dataClipboard="goodsDetail.id">复制</span>
                </p>
            </div>
            <div v-show="goodsDetail.status != '6'" class="orderNum">
                <span>竞拍成功时间</span>
                <span>{{ goodsDetail.auctionTime || '---' }}</span>
            </div>
            <div v-show="goodsDetail.status > 2 && goodsDetail.status != '6' && goodsDetail.status != '7'" class="orderNum">
                <span>付款时间</span>
                <span class="massage fontColor">{{ goodsDetail.paymentTime || '---' }}</span>
            </div>
            <div v-show="goodsDetail.status == '3' || goodsDetail.status == '4'" class="orderNum">
                <span>发货时间</span>
                <span class="massage fontColor">{{ goodsDetail.paymentTime || '---' }}</span>
            </div>
            <div v-show="goodsDetail.status == '4'" class="orderNum">
                <span>确认收货时间</span>
                <span class="massage fontColor">{{ goodsDetail.paymentTime || '---' }}</span>
            </div>
        </section>
        <!-- 到底提醒 -->
        <section class="footer">
            <!-- <p>——已经到底啦——</p> -->
        </section>
        <!-- 支付或取消订单 -->
        <section class="payment">
            <van-tabbar :fixed="isBottom">
                <div class="paymentBtn">
                    <img class="btnImg" @click="contactService" src="@/assets/img/kefu.png" alt="" />
                    <a class="btnA" ref="tels">客服</a>
                </div>
                <div>
                    <button v-show="goodsDetail.status >= 5" class="buy" @click="delOrder"><span>删除</span></button>
                    <button v-show="goodsDetail.status == '1'" class="buy" @click="payForIt"><span>立即付款</span></button>
                    <button v-show="goodsDetail.status == '2'" class="buy" @click="returnMoney"><span>申请退款</span></button>
                    <button v-show="goodsDetail.status == '3'" class="buy" @click="getConfirm"><span>确认收货</span></button>
                    <button v-show="goodsDetail.status == '3' || goodsDetail.status == '4'" class="buy1"
                        @click="searchLogistics"><span>物流查询</span></button>
                </div>
            </van-tabbar>
        </section>
        <!-- 退款详情 -->
        <van-popup class="popup2" round v-model="isReturnShow">
            <div class="popup2-div">
                <p class="popup2-div-top">确认退款</p>
                <p class="popup2-div-center">中标后退款，按照《win生活参拍细则》，将对你的行为进行违约处理，会扣除本次参拍的保证金。</p>
                <div class="popup2-div-bottom">
                    <div>
                        <van-button @click="closeReturn" round size="small" color="#E54320" hairline plain
                            style="width: 2.6rem;"><span style="color: #E54320;">取 消</span></van-button>
                    </div>
                    <div>
                        <van-button @click="confirmReturn" round size="small" color="#E54320" hairline
                            style="width: 2.6rem;"><span style="color: #ffffff;">确 认</span></van-button>
                    </div>
                </div>
            </div>
        </van-popup>

        <!-- 查询物流 -->
        <logistics v-if="showLogistics" :logisticsData="logisticsData" @close="showLogistics = false"></logistics>
        <!-- 立即付款 -->
        <payMoney v-if="showPay" :payInfo="payInfo" @close="showPay = false, getPageInfo()"></payMoney>
    </div>
</template>
  
<script>
import { Toast } from "vant";
import {
    getTelephone
} from "@/api/yundian/orderIdDetail/index.js";
import logistics from './logistics.vue'
import payMoney from './payMoney.vue'
import { getAddress, getOrderDetail, delSouthOrder, getReturnMoney, getGoodsConfirm } from '@/api/southMarketOrder/index.js'
import ClipboardJS from "clipboard";
export default {
    components: { logistics, payMoney },
    data() {
        return {
            show: false,//二维码弹出层
            isReturnShow: false,//退款弹窗
            showLogistics: false,//展示物流弹窗
            showPay: false,//支付弹窗
            isTop: true,//顶部返回
            isBottom: true,//底部按钮
            goodsDetail: {
                goodsAuction: {},
                orderLogistics: {}
            },//商品信息
            telephoneNumber: '',//客服电话
            logisticsData: {},//物流
            picUrl: '',
            payInfo: {},//支付信息
            closeTime: {
                // hours: "",
                minutes: "",
                second: "",
            }, //付款倒计时
            userAddress: {},//收件信息
            timer: null

        };
    },
    created() {
        if (this.$route.query.address) {
            console.log(this.$route.query.address, '888');
        }
    },
    beforeMount() {

    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'ShippingAddress') {
                if (from.params.address) {
                    vm.userAddress = from.params.address
                    // getAddress().then(res => {
                    getAddress({ id: to.query.id, logisticsId: from.params.address.id }).then(res => {
                        if (res.data.code == 0) {
                            Toast.success('地址添加成功')
                            vm.getPageInfo()
                        } else {
                            Toast.fail(res.data.message)
                        }
                    }).catch(() => {
                        Toast.fail('添加失败，请重试')
                    })
                }
            }
        })
    },
    activated() {
        this.getPhone()
        this.getPageInfo()
    },
    mounted() {
        let screenWidth = document.documentElement.getBoundingClientRect().width;
        document.documentElement.style.fontSize = screenWidth / 10 + "px";
        this.$store.commit("setSession", this.$route.query.session);
        this.getPhone()
        this.getPageInfo()
    },
    beforeDestroy() {
        // this.clipboard.destroy();
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
    methods: {
        //待付款倒计时，这里需要判断订单超时怎么办
        getRemainTime(remainTime) {
            this.timer = setInterval(() => {
                let createOrder =
                    new Date(remainTime.replace(/-/g, "/")).getTime() -
                    new Date().getTime();
                if (createOrder <= 0) {
                    clearInterval(this.timer)
                    this.timer = null
                }
                let hours = Math.floor((createOrder / 1000 / 60 / 60) % 24);
                let minutes = Math.floor((createOrder / 1000 / 60) % 60);
                let second = Math.floor((createOrder / 1000) % 60);
                this.closeTime.hours = hours > 0 ? hours : "00";
                this.closeTime.minutes = minutes > 0 ? minutes : "00";
                this.closeTime.second = second > 0 ? second : "00";
            }, 500);
        },
        //复制方法
        copyOrderId(dom) {
            // 1. 绑定剪贴板操作方法(订单号)
            let clipboard = new ClipboardJS(dom, {
                text: function (trigger) {
                    // 2. trigger 就是点击的 dom 元素，所以就可以通过它来获取到它的属性 'dataClipboard' 的值了
                    // 3. 从这个方法返回的 string 就是会复制到剪贴板中的内容，所以可以复制任何内容了，也可以在这里处理一些逻辑
                    // 4. 我在这里就只是单纯的输出了事先绑定好的值
                    // console.log(trigger.getAttribute('dataClipboard'));
                    return trigger.getAttribute("dataClipboard");
                },
            });
            // 5. 当复制成功的时候提示复制成功
            clipboard.on("success", () => {
                // 还可以添加监听事件，如：复制成功后提示
                Toast.success("复制成功");
            });
        },
        getPageInfo() {
            let id = this.$route.query.id
            // let id = '1706874964541542401'
            getOrderDetail({ id: id }).then(res => {
                if (res.data.code == 0) {
                    this.goodsDetail = res.data.data
                    this.goodsDetail.payText = this.goodsDetail.goodsAuction.auctionType == '1' ? '驿马' : this.goodsDetail.goodsAuction.auctionType == '2' ? '金叶子' : this.goodsDetail.goodsAuction.auctionType == '3' ? '官凭' : '无'
                    console.log(this.goodsDetail, '页面数据');
                    this.userAddress = res.data.data.userAddress ? res.data.data.userAddress : {}
                    this.picUrl = this.goodsDetail.goodsAuction.picUrl.split(',')[0] || ''
                    this.logisticsData = res.data.data.jsonobjectLogistics || {}
                    if (res.data.data.status == '1') {
                        this.getRemainTime(res.data.data.countdownTime)
                    }

                } else {
                    Toast(res.data.message)
                }
            }).catch(() => {
                Toast.fail('请求失败，请退出重试')
            })
        },
        //回退
        onClickLeft() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                window.webkit.messageHandlers.close.postMessage("up");
            }
            if (isAndroid) {
                window.android.close();
            }
        },
        //
        sendPhone(type) {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (type == '1') {//付款成功
                if (isIOS) {
                    window.webkit.messageHandlers.southMarketPaySuccess.postMessage("up");
                }
                if (isAndroid) {
                    window.android.southMarketPaySuccess();
                }
            }
            if (type == '2') {//删除订单
                if (isIOS) {
                    window.webkit.messageHandlers.southMarketDeleteOrder.postMessage("up");
                }
                if (isAndroid) {
                    window.android.southMarketDeleteOrder();
                }
            }
            if (type == '3') {//确认收货
                if (isIOS) {
                    window.webkit.messageHandlers.southMarketConfirm.postMessage("up");
                }
                if (isAndroid) {
                    window.android.southMarketConfirm();
                }
            }
            if (type == '4') {//申请退款
                if (isIOS) {
                    window.webkit.messageHandlers.southMarketRefund.postMessage("up");
                }
                if (isAndroid) {
                    window.android.southMarketRefund();
                }
            }
        },
        //联系客服
        contactService() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                this.$refs.tels.href = 'tel://' + this.telephoneNumber;
                this.$refs.tels.click();
            }
            if (isAndroid) {
                window.android.callPhone('' + this.telephoneNumber);
            }
        },
        //获取客服电话
        getPhone() {
            getTelephone().then(res => {
                if (res.data.code == 0) {
                    this.telephoneNumber = res.data.data
                } else {
                    Toast(res.data.message)
                }
            }).catch(() => {
                Toast('客服繁忙中')
            })
        },
        //删除
        delOrder() {
            if (this.goodsDetail.status == '5' || this.goodsDetail.status == '6') {
                delSouthOrder({ id: this.$route.query.id }).then(res => {
                    if (res.data.code == 0) {
                        Toast.success('删除成功')
                        this.sendPhone('2')
                        this.onClickLeft()
                    } else {
                        Toast.fail(res.data.message)
                    }
                }).catch(() => {
                    Toast('删除失败')
                })
            }
        },
        //申请退款
        returnMoney() {
            if (this.goodsDetail.status == '2') {
                this.isReturnShow = true
            }
        },
        //取消退款
        closeReturn() {
            this.isReturnShow = false
        },
        //确认退款
        confirmReturn() {
            getReturnMoney({ id: this.$route.query.id }).then(res => {
                if (res.data.code == 0) {
                    Toast.success('退款申请成功')
                    this.sendPhone('4')
                    this.getPageInfo()
                    this.isReturnShow = false
                } else {
                    Toast.fail(res.data.message)
                }
            }).catch(() => {
                Toast('退款失败，请联系管理员')
            })
        },
        //确认收货
        getConfirm() {
            if (this.goodsDetail.status == '3') {
                getGoodsConfirm({ id: this.$route.query.id }).then(res => {
                    if (res.data.code == 0) {
                        Toast.success('确认成功')
                        this.sendPhone('3')
                        this.getPageInfo()
                    } else {
                        Toast.fail(res.data.message)
                    }
                }).catch(() => {
                    Toast('收货失败，请联系管理员')
                })
            }
        },
        //查询物流
        searchLogistics() {
            if (this.goodsDetail.status == '3' || this.goodsDetail.status == '4') {
                this.showLogistics = true
            }
        },
        //立即付款
        payForIt() {
            if (this.goodsDetail.userAddress) {

                this.payInfo = {
                    id: this.goodsDetail.auctionId,
                    auctionType: this.goodsDetail.goodsAuction.auctionType,
                    payText: this.goodsDetail.payText,
                    bidding: this.goodsDetail.bidding,
                    margin: this.goodsDetail.goodsAuction.margin
                }
                this.showPay = true
            } else {
                Toast('请添加收货地址')
            }
        }
    }
};
</script>
  
<style lang="less" scoped>
.main {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .popup {
        box-sizing: border-box;
        padding: 20px 20px;

        .codeQR {
            // width: 1rem;
            height: 6rem;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: flex-start;

            .clipboardSpan {
                color: #E54320;
                text-decoration: underline;
            }
        }
    }

    .popup2 {
        width: 70%;
        height: 22%;
        // box-sizing: border-box;
        padding: 0 10px;

        &-div {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            &-top {
                margin: 10px;
                font-size: 16px;
                color: #832604;
            }

            &-bottom {
                width: 100%;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }
    }

    section {
        background: #fff;
        width: 100%;
        box-sizing: border-box;
    }



    .fontColor {
        line-height: 0.7784rem;
        color: #666666;
        font-size: 0.3459rem;
    }

    .top {
        height: 6%;
        font-size: 1.2973rem;
    }

    .willPayLog {
        display: flex;
        padding: 0.4054rem 0.4054rem;
        align-items: center;
        background-color: #ffffff;
        border-radius: 0.1351rem;
        flex-direction: column;
    }

    .willPay {
        display: flex;
        padding: 0.4324rem 0.4324rem 0.6rem 0.4324rem;
        border-bottom: 1px solid #E5E5E5;



        .willPayD1 {
            width: 1rem;
            display: flex;
            align-items: center;
        }

        img {
            height: 0.6rem;
            width: 0.6rem;
            margin-right: 0.3rem;
        }
    }

    .shopInfo {
        display: flex;
        flex-flow: column nowrap;
        padding: 0 0.5135rem;

        .goodsInfo {
            display: flex;
            flex-flow: column nowrap;
            padding: 0.4324rem 0;
            border-bottom: 1px solid rgba(170, 170, 170, .4);

            .goodsList {
                display: flex;
                flex-flow: row nowrap;

                .goodsImg {
                    width: 3rem;
                    height: 3rem;
                }

                .goodsText {
                    margin-left: 10px;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: space-between;

                    p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .price {
                        color: #E54320;
                        font-size: 12px;
                    }

                    .size {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }

            .returnGoods {
                padding: 0.4324rem 0 0 0;
                display: flex;
                justify-content: flex-end;

                button {
                    max-width: 2.8rem;
                    min-width: 2.4rem;
                    height: 0.8649rem;
                    margin-right: 0.6919rem;
                    border: 1px solid rgb(202, 202, 202);
                    background: #fff;
                    border-radius: 0.4324rem;
                }

                .buy {
                    background: #E54320;
                    color: #fff;
                    border: none;
                }

                .buy1 {
                    background: #ffffff;
                    color: #333333;
                    border: 1px solid #999999;
                }
            }

            .otherText {
                padding-top: 0.4324rem;

                .integrate {
                    display: flex;
                    justify-content: space-between;
                }

                .freight {
                    display: flex;
                    justify-content: space-between;
                }



                .remark {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

    }

    .orderInfo {
        padding: 0.4324rem 0.5135rem;
        line-height: 0.7568rem;

        .orderNum {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .orderNum1 {
                min-width: 1.8rem;
            }

            .orderNumP {
                display: flex;
                align-items: center;
                justify-content: end;

                .orderNumPS {
                    flex: 1;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .copy {
                color: #E54320;
                margin-left: 0.2595rem;
                text-decoration: underline;
            }

            .massage {
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -moz-box;
                -moz-line-clamp: 2;
                -moz-box-orient: vertical;
                overflow-wrap: break-word;
                word-break: break-all;
                white-space: normal;
                overflow: hidden;
            }
        }
    }

    .payment {
        .van-tabbar {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .paymentBtn {
                margin-left: 15px;
                padding: 15px;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;

                .btnImg {
                    width: 0.6rem
                }

                .btnA {
                    color: #000;
                    font-size: 12px;
                }
            }

            button {
                width: 2.5946rem;
                height: 0.8649rem;
                margin-right: 0.6919rem;
                border: 1px solid #999999;
                background: #fff;
                border-radius: 0.4324rem;
            }

            .buy {
                background: #E54320;
                color: #fff;
                border: none;
            }

            .evaluate {
                border: none;
                background: #E54320;
                color: #fff;
            }

        }

    }

    .footer {
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}
</style>
  